<template>
  <div class="invoice-container">
    
    <p class="section-title">{{ $t('PUBLIC.INVOICE.VERIFY_INVOICE_ADDRESS') }}</p>

    <div class="form-row">
      <div class="form-col full-width">
        <memlist-text-input
          id="in_personnr"
          name="in_personnr"
          :title="$t('MEMBER.PERSONNR')"
          layout="vertical"
          v-model="local_item.in_personnr"
        />
        <p class="error_field" v-if="invalid_input === 'in_personnr'"></p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <memlist-text-input
          id="firstname"
          name="firstname"
          :title="$t('PUBLIC.INVOICE.FIRSTNAME')"
          layout="vertical"
          v-model="local_item.firstname"
        />
      </div>
      <div class="form-col">
        <memlist-text-input
          id="lastname"
          name="lastname"
          :title="$t('PUBLIC.INVOICE.LASTNAME')"
          layout="vertical"
         
          v-model="local_item.lastname"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <memlist-text-input
          id="address"
          name="address"
          :title="$t('PUBLIC.INVOICE.ADDRESS')"
          layout="vertical"
         
          v-model="local_item.address"
        />
      </div>
      <div class="form-col">
        <memlist-text-input
          id="co"
          name="co"
          :title="$t('PUBLIC.INVOICE.CO')"
          layout="vertical"
         
          v-model="local_item.co"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <memlist-text-input
          id="zipcode"
          name="zipcode"
          :title="$t('PUBLIC.INVOICE.ZIPCODE')"
          layout="vertical"
         
          v-model="local_item.zipcode"
        />
      </div>
      <div class="form-col">
        <memlist-text-input
          id="post"
          name="post"
          :title="$t('PUBLIC.INVOICE.POST')"
          layout="vertical"
         
          v-model="local_item.post"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-col full-width">
        <memlist-text-input
          id="country"
          name="country"
          :title="$t('PUBLIC.INVOICE.COUNTRY')"
          layout="vertical"
         
          v-model="local_item.country"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-col full-width">
        <memlist-text-input
          id="email"
          name="email"
          :title="$t('PUBLIC.INVOICE.EMAIL')"
          layout="vertical"
          v-model="local_item.email"
        />
        <p class="error_field" v-if="invalid_input === 'email'"></p>
      </div>
    </div>

    <button class="submit-button" @click.prevent="create_invoice_clicked()">{{ $t('COMMON.OK') }}</button>
    <p v-if="invalid_input" class="info-text">{{ $t('PUBLIC.INVOICE.INPUT_FIELDS_MISSING') }}</p>

    <div v-if="loading" class="spinner-container">
      <div class="spinner"></div>
    </div>

    <p class="error-message" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import validate_pers_number from '@/core/services/personnr';
import { validate_email } from '@/core/services/email';

export default {
  name: 'InvoicePaymentComponent',
  props: ['order','personal_details'],
  emits: ['updated', 'start_polling'],
  mixins: [ toasts ],
  data() {
    return {
      local_item: {},
      local_order: null,
      error: null,
      loading: false,
      invalid_input: false
    };
  },
  mounted() {
    if (this.order) {
      this.local_order = { ...this.order };
    }
  },
  watch: {
    personal_details: {
      deep: true,
      async handler(val) {
        if (this.personal_details) {
          this.local_item = { ...this.personal_details };

          this.local_item.in_personnr = this.personal_details.fmt_personnr || this.personal_details.in_personnr || this.personal_details.personnr;
        }
        else {
          const res = await axios.get(`/shop_order/token/${this.local_order.token}/${this.local_order.shop_order_id}/`);

          if (res.status === 200) {
            this.local_item = res.data.member;
          }
        }
      },
      immediate: true
    },
    order: {
      deep: true,
      handler(val) {
        this.local_order = { ...this.order };
      }
    }
  },
  methods: {
    async create_invoice_clicked() {

      const personnr_valid = validate_pers_number(this.local_item.in_personnr);
      const email_valid = validate_email(this.local_item.email);

      if (!personnr_valid.valid) {
        this.invalid_input = 'in_personnr';
        return;
      }

      if (!email_valid) {
        this.invalid_input = 'email';
        return;
      }

      this.invalid_input = false;


      this.loading = true;
      try {
        const res = await axios.post(`/invoice/paymentintent/${this.local_order.token}/${this.local_order.shop_order_id}/confirm`, {
          shop_order_id: this.local_order.shop_order_id,
          recipient: {
            firstname: this.local_item.firstname,
            lastname: this.local_item.lastname,
            address: this.local_item.address,
            co: this.local_item.co,
            zipcode: this.local_item.zipcode,
            post: this.local_item.post,
            country: this.local_item.country,
            email: this.local_item.email,
            in_personnr: this.local_item.in_personnr
          }
        });

        if (res.status === 201) {
          // invoice created successfully
          this.$emit('done', res.data.shop_order);
        } else {
          this.error = this.$t('PUBLIC.INVOICE.ERROR_CREATING_INVOICE');
        }
      } catch (err) {
        console.error('create_invoice_clicked error', err);
        this.error = this.$t('PUBLIC.INVOICE.ERROR_CREATING_INVOICE');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.invoice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
  background-color: white;
  border-radius: 8px;
}

.error-message {
  font-weight: bold;
  text-align: center;
  color: red;
  margin-bottom: 1em;
}

.section-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1em;
}

.form-col {
  flex: 1;
  min-width: 0;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
}



.form-col.full-width {
  flex: 100%;
}

.submit-button {
  font-size: 22px;
  min-height: 35px;
  min-width: 280px;
  padding: 0.5em 1em;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background: #0069d9;
}

.info-text {
  text-align: center;
  margin-top: 1em;
  color: #555;
}

.spinner-container {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.spinner {
  border: 4px solid #ccc;
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
  }
}

.error_field {
  color: red;
}
</style>
